import {
  convertForumFacetsFilterToRequestParams,
  convertForumFacetsRequestParamsToFilter,
  shouldRequestContentTypeFacet,
} from '../forum';

import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';
import { WarmupDataKey } from './warmupDataKey';

export const getForumContentTypeFacetResponse = async (
  searchParams: SearchParams,
) => {
  const { searchRequest, previousSearchResponse } = searchParams;

  const filter = convertForumFacetsRequestParamsToFilter(searchRequest);

  if (!filter.contentType) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestContentTypeFacet(searchParams)) {
    return getSearchResponse({
      ...searchParams,
      searchRequest: {
        ...searchRequest,
        ...convertForumFacetsFilterToRequestParams(),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      shouldShowSamples: false,
      warmupDataKey: WarmupDataKey.ForumContentTypeFacetResponse,
    });
  } else {
    return previousSearchResponse;
  }
};
