import {
  convertForumFacetsFilterToRequestParams,
  convertForumFacetsRequestParamsToFilter,
  shouldRequestCategoriesFacet,
} from '../forum';
import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';
import { WarmupDataKey } from './warmupDataKey';

export const getForumCategoriesFacetResponse = async (
  searchParams: SearchParams,
) => {
  const { searchRequest, previousSearchResponse } = searchParams;

  const filter = convertForumFacetsRequestParamsToFilter(searchRequest);

  if (!filter.categoryTitle) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestCategoriesFacet(searchParams)) {
    return getSearchResponse({
      ...searchParams,
      searchRequest: {
        ...searchRequest,
        ...convertForumFacetsFilterToRequestParams({
          contentType: filter.contentType,
        }),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      shouldShowSamples: false,
      warmupDataKey: WarmupDataKey.ForumCategoriesFacetResponse,
    });
  } else {
    return previousSearchResponse;
  }
};
