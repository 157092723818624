import {
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
  isCollectionsSelectionChanged,
  isMaxPriceSelected,
  isMinPriceSelected,
  shouldRequestFacet,
} from '../products';
import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';
import { WarmupDataKey } from './warmupDataKey';

export const getPriceFacetResponse = (searchParams: SearchParams) => {
  const { searchRequest, previousSearchResponse } = searchParams;

  const filter = convertProductFacetsRequestParamsToFilter(searchRequest);

  if (!isMinPriceSelected(filter) && !isMaxPriceSelected(filter)) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestFacet(searchParams, [isCollectionsSelectionChanged])) {
    return getSearchResponse({
      ...searchParams,
      searchRequest: {
        ...searchRequest,
        ...convertProductFacetsFilterToRequestParams({
          collections: filter.collections,
        }),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      shouldShowSamples: false,
      warmupDataKey: WarmupDataKey.ProductsPriceFacetResponse,
    });
  } else {
    return previousSearchResponse;
  }
};
