import {
  ILocationSearchRequest,
  IEncodedSearchPathParams,
  IEncodedSearchQueryParams,
} from '../location.types';

import {
  decodeQuery,
  decodeQueryFromPath,
  decodeDocumentType,
  decodePage,
  decodeSort,
  decodeProductPrice,
  decodeProductCollections,
  decodeForumContentType,
  decodeForumCategory,
} from './serializers';

export function decodeSearchRequestFromPathParams(
  params: IEncodedSearchPathParams,
): ILocationSearchRequest {
  const query = decodeQueryFromPath(params.q);
  const documentType = decodeDocumentType(params.qc);
  const page = decodePage(params.page);

  return {
    ...(query !== undefined && { query }),
    ...(documentType !== undefined && { documentType }),
    ...(page !== undefined && { page }),
  };
}

export function decodeSearchRequestFromQueryParams(
  params: IEncodedSearchQueryParams,
): ILocationSearchRequest {
  const query = decodeQuery(params.q);
  const documentType = decodeDocumentType(params.type);
  const page = decodePage(params.page);
  const sort = decodeSort(params.sort);
  const { minPrice, maxPrice } = decodeProductPrice(params.price);
  const collections = decodeProductCollections(params.collections);
  const contentType = decodeForumContentType(params.contentType);
  const categoryTitle = decodeForumCategory(params.categoryTitle);

  return {
    ...(query !== undefined && { query }),
    ...(documentType !== undefined && { documentType }),
    ...(page !== undefined && { page }),
    ...(minPrice !== undefined && { minPrice }),
    ...(maxPrice !== undefined && { maxPrice }),
    ...(collections !== undefined && { collections }),
    ...(sort !== undefined && { sort }),
    ...(contentType !== undefined && { contentType }),
    ...(categoryTitle !== undefined && { categoryTitle }),
  };
}
